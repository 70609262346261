<template>
  <button
    class="button is-danger"
    @click.prevent="exportToExcel"
    type="button"
  >
    {{ buttonTitle }}
  </button>
</template>

<script>
import { ref } from "vue";
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import {handleFilter} from "@/use/useFilter";

export default {
  name: 'ExportData',
  props: {
    buttonTitle : {
      type: String,
      default: 'Export'
    },
    projectName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'Report'
    },
    reportType: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const {
      getFilteredIssues: exportIssues,
      getTotalTimeSpend: totalTimeSpend
    } = handleFilter()

    const listData = exportIssues
    const projectName = ref(props.projectName)
    const reportTitle = ref(props.name)
    const reportType = ref(props.reportType)

    const wbConfig = {
      creator: 'TUAN NGUYEN',
      created: new Date(),
      fitToPage: true,
      orientation: 'landscape'
    }

    // convert
    function convertDuration (seconds) {
      let hours = parseFloat(seconds / 3600)
      let str = hours.toFixed(2)
      return str
    }

    // export
    async function exportToExcel () {
      const wb = new ExcelJS.Workbook()
      const ws = wb.addWorksheet("Report Gitlab", wbConfig)
      // start
      ws.addRow()
      ws.addRow(['Project: ', projectName.value ])
      // add empty row
      ws.addRow(['Total time:', convertDuration(totalTimeSpend.value) ])
      // add empty row
      ws.addRow()

      if (reportType.value == 1) {
         ws.columns = [
          { header: 'A', key: 'a', width: 10 },
          { header: 'B', key: 'b', width: 50 },
          { header: 'C', key: 'c', width: 20 },
          { header: 'D', key: 'd', width: 20 },
          { header: 'E', key: 'e', width: 60 },
          { header: 'F', key: 'f', width: 10 }
        ]

        listData.value.forEach(item => {
          ws.addRow()
          ws.addRow()
          ws.addRow(['','Person: ', item.person, '' ])
          ws.addRow(['','Spend: ', convertDuration(item.spent), '' ])
          ws.addRow()
          item.issues.forEach(task => {
            ws.addRow([ task.idd, task.title, new Date(task.createdAt), convertDuration(task.totalTimeSpent), task.webUrl ])
          })
          ws.addRow()
        });

      } else {
        ws.columns = [
          { header: 'A', key: 'iid', width: 10 },
          { header: 'B', key: 'task', width: 60 },
          { header: 'C', key: 'assignee', width: 20 },
          { header: 'D', key: 'createdat', width: 20 },
          { header: 'E', key: 'spent', width: 20 },
          { header: 'F', key: 'link', width: 40 }
        ]
        const tableHeader = ws.addRow(['ID', 'Task name', 'Assignee', 'Created At', 'Spent', 'Link'])
        tableHeader.font =  { bold: true }
        listData.value.forEach(item => {
          // get assignee
          let assigner = ''
          item.assignees.nodes.forEach(item => {
            assigner += item.name
          })
          ws.addRow([item.idd, item.title, assigner, new Date(item.createdAt), item.totalTimeSpent, item.webUrl ])
        });
      }

      // empty row
      ws.addRow()
      ws.addRow()
      ws.addRow()
      const buf = await wb.xlsx.writeBuffer()
      const reportName = reportTitle.value.replace(/\s+/g, '-').toLowerCase() + '-' + projectName.value.replace(/\s+/g, '-').toLowerCase() + '-' + Date.now().toString()
      saveAs(new Blob([buf]),  `${reportName}.xlsx`)
    }

    return {
      exportToExcel,
      listData
    }
  }
}
</script>

<style lang="scss" scoped>

</style>